.portfolio_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 1rem auto;
    padding: 1rem;
    gap: 3rem;
}

/* PORTFOLIO HEADING */

.portfolio_heading {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

#portfolio_title {
    padding: 1rem 2rem;
    border: 1px solid black;
    background-color: var(--primary-color-light);
    border-radius: 1.5rem;
    font-weight: 500;
    font-size: 1.5rem;
}

/** PORTFOLIO GRID */

.portfolio_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

/* PORTFOLIO MAIN PRODUCT */

.portfolio_main_product {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 3rem;
    padding: 1rem;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.portfolio_main_product_image {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#main_product_image {
    width: 15rem;
    height: 15rem;
    object-fit: cover;
}

#main_product_title {
    font-size: 1.5rem;
}

.portfolio_main_product_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
}

#main_product_slogan {
    font-size: 3rem;
    font-weight: 400;
    text-align: center;
}

#main_product_developers {
    font-size: 1.2rem;
    font-weight: 300;
}

.portfolio_main_product_cta {
    display: flex;
    justify-content: center;
    align-items: center;
}

#main_product_cta {
    padding: 1rem 2rem;
    background-color: black;
    color: white;
    border-radius: .3rem;
}

#main_product_cta:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
}

/* PORTFOLIO OTHER PRODUCTS */

.portfolio_other_product {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.portfolio_other_product span {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: center;
}

.portfolio_other_product_image {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.other_product_image {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
}

.other_product_title {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
}

.other_product_slogan {
    text-align: center;
    font-size: 1.5rem;
    word-wrap: break-word;
    max-width: 90%;
}

.portfolio_other_product_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.portfolio_other_product_cta {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.other_product_cta {
    padding: 1rem 2rem;
    background-color: var(--primary-color-light);
    color: black;
    border: 1px solid rgb(99, 95, 95);
    border-radius: .3rem;
    transition: all 0.8 ease-in-out;
}

.other_product_cta:hover {
    transform: scale(0.95);
    transition: all 0.8 ease-in-out;
}

/* DIVING LINE */

#other_product_separator {
    width: 80%;
    margin: 0 auto;
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
}

/**
* MEDIA QUERIES
*/

/* 900 PX */

@media screen and (max-width: 900px) {
    /* FONT SIZES */
    #main_product_slogan {
        font-size: 2.5rem;
    }
}

/* 700 PX */

@media screen and (max-width: 700px) {
    /* GRID */
    .portfolio_grid {
        grid-template-columns: 1fr;
    }

    /* PORTFOLIO MAIN PRODUCT */
    .portfolio_main_product {
        width: 100%;
        padding: 3rem 0;
    }

    /* PORTFOLIO OTHER PRODUCTS */
    .portfolio_other_product {
        width: 100%;
        display: grid;
        grid-template-columns: 42% 5% 42%;
        gap: .5rem;
        justify-content: center;
    }

    .portfolio_other_product span {
        width: 100%;
        padding: 2rem 0;
        height: 100%;
        justify-content: space-evenly;
    }

    /* PORTFOLIO OTHER PRODUCTS SEPARATOR */
    #other_product_separator {
        transform: rotate(90deg);
        padding: 0;
        width: 100%;
    }
}

/* 500 PX */

@media screen and (max-width: 500px) {
    /* PORTFOLIO MAIN PRODUCT */
    .portfolio_main_product {
        gap: 1rem;
    }

    /* PORTFOLIO OTHER PRODUCTS */
    .portfolio_other_product {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .portfolio_other_product span {
        width: 100%;
        padding: 1rem 0;
        height: 100%;
        justify-content: space-evenly;
    }

    #other_product_separator {
        transform: rotate(0deg);
        padding: 0;
        width: 100%;
    }
}