/* CONTACT CONTAINER */

.contact_container {
    width: 80%;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
}

/* CONTACT HEADER */

.contact_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    text-align: center;
}

.contact_header_heading {
    font-weight: 500;
}

.contact_heading_text {
    font-weight: 250;
    font-size: 1.5rem;
}

/* CONTACT FORM */

.contact_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.home_contact_form {
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 50%;
}

.contact_form_addresses {
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.contact_form_input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    width: 50%;
}

.home_contact_form label {
    font-size: 1.4rem;
    font-weight: 300;
}

.contact_form_input input {
    width: 70%;
    height: 4rem;
    outline: none;
    border-radius: .3rem;
    padding: 0 .5rem;
    font-size: 1.4rem;
    font-weight: 300;
    border: 1px solid black;
}

.contact_form_input input:focus {
    outline: 2px solid var(--primary-color);
    border: 1px solid black;
}

.contact_form_message {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#contact_message {
    width: 85%;
    resize: none;
    height: 20rem;
    padding: 2rem;
    font-size: 1.5rem;
    outline: none;
    border-radius: .3rem;
    padding: 0 .5rem;
    font-size: 1.4rem;
    font-weight: 300;
    border: 1px solid black;
    padding: 2rem;
    font-family: 'Poppins', sans-serif;
}

#contact_message:focus {
    outline: 2px solid var(--primary-color);
    border: 1px solid black;
}

.contact_form_feedbacks {
    display: none;
}

.conact_form_cta {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    margin: 1rem 0;
}

.contact_submit {
    padding: 1rem 1.5rem;
    border: 1px solid black;
    background-color: black;
    color: white;
    border-radius: .3rem;
    cursor: pointer;
}

.contact_submit:hover {
    transform: scale(.85);
    background-color: var(--primary-color-light);
    color: black;
}

/**
* MEDIA QUERIES
*/

/* 1100 PX */

@media screen and (max-width: 1100px) {
    /* CONTACT FORM */
    .home_contact_form {
        width: 60%;
    }
}

/* 1000 PX */

@media screen and (max-width: 1000px) {
    /* CONTACT FORM */
    .home_contact_form {
        width: 70%;
    }
    /* CONTACT FORM INPUT */
    .contact_form_input input {
        width: 80%;
    }
    /* CONTACT HEADER */
    .contact_header_heading {
        font-size: 3rem;
        font-weight: 500;
    }
}

/* 800 PX */

@media screen and (max-width: 800px) {
    /* CONTACT FORM */
    .home_contact_form {
        width: 80%;
    }
    /* CONTACT FORM INPUT */
    .contact_form_input input {
        width: 80%;
    }
}

/* 700 PX */

@media screen and (max-width: 700px) {
    /* CONTACT FORM */
    .home_contact_form {
        width: 90%;
    }
    /* CONTACT FORM INPUT */
    .contact_form_input input {
        width: 90%;
    }
}

/* 600 PX */

@media screen and (max-width: 600px) {
    /* CONTACT FORM */
    .home_contact_form {
        width: 100%;
    }
    /* CONTACT FORM ADDRESSES */
    .contact_form_addresses {
        flex-direction: column;
        gap: 2rem;
    }
    /* CONTACT FORM INPUT */
    .contact_form_input {
        width: 70%;
    }
}

/* 500 PX */

@media screen and (max-width: 500px) {
    /* CONTACT FORM */
    .home_contact_form {
        width: 100%;
    }
    /* CONTACT FORM ADDRESSES */
    .contact_form_addresses {
        flex-direction: column;
        gap: 2rem;
    }
    /* CONTACT FORM INPUT */
    .contact_form_input {
        width: 80%;
    }
}

/* 350 PX */

@media screen and (max-width: 350px) {
    /* CONTACT FORM */
    .home_contact_form {
        width: 100%;
    }
    /* CONTACT FORM ADDRESSES */
    .contact_form_addresses {
        flex-direction: column;
        gap: 2rem;
    }
    /* CONTACT FORM INPUT */
    .contact_form_input {
        width: 90%;
    }
}