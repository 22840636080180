/**
ABOUT PAGE 
*/

.about_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

/* MAIN CONTAINER */

.about_hero {
    width: 90%;
    margin: 5rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;
    min-height: 120vh;
}

/* ABOUT HERO HEADER */

.about_hero_header {
    display: flex;
    flex-direction: column;
    gap: .1rem;
}

.about_hero_smallText {
    font-size: 1.6rem;
    font-weight: 300;
}

.about_hero_heading {
    font-size: 6rem;
    font-weight: 600;
}

/* ABOUT HERO IMAGES */

.about_hero_images {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
}

#about_hero_image {
    height: 50%;
    margin: 0 auto;
    object-fit: contain;
}

/**
    ABOUT HERO VISION MISSION
*/

.about_vision_mission {
    width: 80%;
    margin: 5rem auto;
    display: flex;
    gap: 5rem;
    text-align: center;
}

.about_vision_mission p {
    font-size: 4.5rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 1rem 2rem;
    background-color: black;
    color: whitesmoke;
    border-radius: .5rem;
    cursor: pointer;
    background-image: linear-gradient(to right, var(--lens-umuhinzi), var(--lens-nyibutsa));
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.about_vision_mission p:hover {
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
    transition: all .3s ease-in-out;
    transform: scale(1.02);
}

.about_vision_mission h3 {
    font-size: 3rem;
    font-weight: 500;
}

.about_vision {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.about_mission {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-top: 25%;
}

/**
    ABOUT US
*/

/* ABOUT US CONTAINER */
.about_us {
    width: 90%;
    margin: 4rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.about_us h2 {
    max-width: 90%;
    font-size: 3.5rem;
    font-weight: 500;
    text-align: center;
}

.about_us_button {
    padding: 1.5rem 3rem;
    background-color: black;
    color: white;
    border-radius: .5rem;
    font-size: 1.5rem;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all .3s ease-in-out;
    margin: 0 auto;
}

.about_us_button:hover {
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
    transition: all .3s ease-in-out;
    transform: scale(1.02);
}

/**
    ABOUT NICHE
*/

/* ABOUT NICHE HEADER */
.about_niche {
    width: 80%;
    margin: 5rem auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
}

.about_niche_header p {
    max-width: 50%;
    font-size: 2rem;
    font-weight: 300;
}

.about_niche_header h2 {
    font-size: 3.5rem;
    font-weight: 600;
    text-align: end;
}

/* ABOUT NICHE CARDS */

.about_niche_products {
    display: flex;
    gap: 2rem;
}

.about_niche_product {
    width: fit-content;
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    border-radius: 1rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    padding: 4rem 1rem;
    height: fit-content;
}

.niche_product_sector {
    font-size: 1.2rem;
    color: gray;
}

.niche_product_description {
    width: 80%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    gap: .3rem;
}

.niche_product_name {
    font-size: 2rem;
    font-weight: 700;
}

.niche_product_button {
    padding: 1rem 3rem;
    background-color: black;
    color: white;
    border: none;
    border-radius: .3rem;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.niche_product_button:hover {
    transform: scale(1.05);
}

/* NYIBUTSA */

.nyibutsa {
    margin-top: 25%;
    background-color: var(--lens-nyibutsa);
}

.nyibutsa .niche_product_button {
    background-color: var(--primary-color);
    color: var(--lens-nyibutsa);
}

.nyibutsa .niche_product_description {
    color: white;
}

/* UMUHINZI */

.umuhinzi {
    background-color: var(--lens-umuhinzi);
    color: white;
}

.umuhinzi .niche_product_sector {
    color: var(--primary-color);
}

.umuhinzi .niche_product_button {
    background-color: var(--primary-color);
    color: black;
}